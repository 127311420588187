import React from "react";
import "./Common.css";

export default () => {
  return (
    <div className="logo-container">
      <span className="bold-logo-text">watch</span>
      <span className="light-logo-text">dogger</span>
    </div>
  );
};
