import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { UserContext } from "../../Store/UserProvider";

const PublicRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(UserContext);
  return (
    <Switch>
      <Route {...rest} render={routeProps => (!!currentUser ? <Redirect to={"/watchdogs"} /> : <RouteComponent {...routeProps} />)} />
    </Switch>
  );
};

export default PublicRoute;
