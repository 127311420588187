import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default props => {
  const useStyles =
    props.variant === "outlined"
      ? makeStyles(theme => ({
          button: {
            // margin: theme.spacing(1),
            borderRadius: "0px",
            color: props.color,
            borderColor: props.color
          }
        }))
      : makeStyles(theme => ({
          button: {
            // margin: theme.spacing(1),
            borderRadius: "0px",
            backgroundColor: props.color,
            color: props.textColor,
            "&:hover": {
              backgroundColor: props.hoverColor,
              borderColor: props.hoverColor,
              boxShadow: "none"
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: props.hoverColor,
              borderColor: props.hoverColor
            },
            "&:focus": {
              outline: "none"
            }
          }
        }));

  const classes = useStyles();

  const styles = {};
  if (props.width === "100") {
    styles.width = "100%";
  }

  return (
    <Button
      variant={props.variant}
      styles={styles}
      size={props.size ? props.size : "medium"}
      className={classes.button}
      disableElevation
      type={props.type && props.type}
      onClick={props.onClick && props.onClick}
    >
      {props.children}
    </Button>
  );
};
