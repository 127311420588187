import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { AppContext } from "../Store/AppProvider";

export default () => {
  const appContext = useContext(AppContext);
  return (
    appContext.showSpinner.get && (
      <div className="spinner-container">
        <Spinner className="spinner" size="xl" />
      </div>
    )
  );
};
