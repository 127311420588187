import React, { useContext } from "react";
import { SwitchContext } from "../../Store/SwitchProvider";
import Switch from "@material-ui/core/Switch";
import Tooltip from "../MaterialComponents/MaterialTooltip";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { UserContext } from "../../Store/UserProvider";
import { setSwitchActive } from "../../Firebase/switch";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#000000"
    }
  }
});

export default props => {
  const { switchDispatch } = useContext(SwitchContext);
  const { currentUserProfile } = useContext(UserContext);

  let toggleActive = async () => {
    try {
      await setSwitchActive(currentUserProfile.id, props.switchId, !props.active);
      switchDispatch({ type: "UPDATE", data: { active: !props.active }, id: props.switchId });
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip text={props.active ? "Deactivate" : "Activate"}>
        <Switch size="small" checked={props.active} onChange={toggleActive} inputProps={{ "aria-label": "secondary checkbox" }} />
      </Tooltip>
    </MuiThemeProvider>
  );
};
