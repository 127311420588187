import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import "./Auth.css";
import Logo from "../Logo";
import MaterialButton from "../MaterialComponents/MaterialButton";

export default props => {
  return (
    <div className="d-flex flex-column justify-content-center flex-grow-1" style={{ backgroundColor: "black" }}>
      <Logo />
      <div className="form-container">
        <h2 className="form-header">{props.formTitle}</h2>
        <Form className="form" onSubmit={props.handleSubmit}>
          <FormGroup row>
            <Label className="auth-form-label" for="email" sm="3" md="2">
              Email:
            </Label>
            <Col sm="8" md="9">
              <Input className="auth-input" type="email" name="email" id="email" placeholder="Enter Email" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label className="auth-form-label" for="password" sm="3" md="2">
              Password:
            </Label>
            <Col sm="8" md="9">
              <Input className="auth-input" type="password" name="password" id="password" placeholder="Enter Password" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col className="center">
              {/* <Button color="info">{props.submitText}</Button> */}
              <MaterialButton type="submit" textColor="white" size="medium" color="#0098d4" hoverColor="#006c96" variant="contained" width="100">
                {props.submitText}
              </MaterialButton>
            </Col>
          </FormGroup>
          <hr className="divider" />
          <div className="center">
            {/* <Button className="external-auth-button" onClick={props.handleGoogleSubmit}> */}
            <MaterialButton
              onClick={props.handleGoogleSubmit}
              textColor="white"
              size="medium"
              color="#0098d4"
              hoverColor="#006c96"
              variant="contained"
              width="100"
            >
              <span className="icon-container">
                <FontAwesomeIcon icon={faGoogle} />
              </span>
              {props.googleButtonText}
            </MaterialButton>
          </div>
          <div className="center" style={{ marginTop: "30px" }}>
            {props.redirectText}{" "}
            <Link className="auth-link" to={props.redirectHref} onClick={() => props.setIsLogin(!props.isLogin)}>
              {props.redirectLinkText}
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};
