import React, { useEffect, useState, useReducer } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import MaterialButton from "../MaterialComponents/MaterialButton";
import moment from "moment";
import { getAlerts, subscribeCheckins, getCheckins, subscribeAlerts } from "../../Firebase/switch";

export default props => {
  const fetchCount = 10;
  const checkinReducer = (state, action) => {
    let s = {};
    switch (action.type) {
      case "ADD":
        Object.keys(state).forEach(k => {
          s[k] = state[k];
        });
        s[action.id] = action.data;
        return s;
      case "SET":
        return action.data;
      default:
        throw new Error();
    }
  };
  const alertReducer = (state, action) => {
    let s = {};
    switch (action.type) {
      case "ADD":
        Object.keys(state).forEach(k => {
          s[k] = state[k];
        });
        s[action.id] = action.data;
        return s;
      case "SET":
        return action.data;
      default:
        throw new Error();
    }
  };
  const [checkins, checkinDispatch] = useReducer(checkinReducer, {});
  const [alerts, alertDispatch] = useReducer(alertReducer, {});
  const [events, setEvents] = useState([]);
  const [moreButtonText, setMoreButtonText] = useState("Load More");

  useEffect(() => {
      getCheckins(props.switchId, fetchCount, null)
      .then(checkins => {
        if (Object.keys(checkins).length > 0) {
            getAlerts(props.userId, props.switchId, checkins[Math.min(...Object.keys(checkins))].eventTimestamp)
            .then(alerts => {
              alertDispatch({ type: "SET", data: alerts });
            })
            .catch(err => console.log(err));
          checkinDispatch({ type: "SET", data: checkins });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [props.switchId, props.userId]);

  useEffect(() => {
    let unsubscribe = subscribeCheckins(props.switchId, (id, data) => {
      checkinDispatch({ type: "ADD", id: id, data: data });
    });
    return () => unsubscribe();
  }, [props.switchId]);

  useEffect(() => {
    let unsubscribe = subscribeAlerts(props.userId, props.switchId, (id, data) => {
      alertDispatch({ type: "ADD", id: id, data: data });
    });
    return () => unsubscribe();
  }, [props.switchId, props.userId]);

  useEffect(() => {
    let ces = Object.keys(checkins).map(k => {
      return {
        ...checkins[k],
        eventType: "checkin"
      };
    });
    let aes = Object.keys(alerts).map(k => {
      return {
        ...alerts[k],
        eventType: "alert"
      };
    });
    let es = ces.concat(aes);
    setEvents(es.sort((a, b) => b.eventTimestamp - a.eventTimestamp));
  }, [checkins, alerts]);

  const getMoreCheckins = (snapshot, fetchCount) => {
    if (!snapshot || !fetchCount) {
      return;
    }
      getCheckins(props.switchId, fetchCount, snapshot)
      .then(checkins => {
        if (Object.keys(checkins).length === 0) {
          let text = moreButtonText;
          setMoreButtonText("None Found");
          setTimeout(() => {
            setMoreButtonText(text);
          }, 2000);
        } else {
          Object.keys(checkins).forEach(id => {
            checkinDispatch({ type: "ADD", id: id, data: checkins[id] });
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Container className="center" style={{ marginTop: "3rem" }}>
      <Row>
        <Col>
          <h3 style={{ borderBottom: "1px solid #c1c1c1", paddingBottom: "8px" }}>Past Checkins and Alerts</h3>
          <Table responsive>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Log</th>
                <th>IP Address</th>
                <th>Method</th>
              </tr>
            </thead>
            <tbody>
              {events
                // .sort((a, b) => b - a)
                .map((e, i) => {
                  if (e.eventType === "checkin") {
                    return (
                      <tr key={i}>
                        <td className="d-none d-md-table-cell">{moment(e.eventTimestamp.toDate()).format("M/DD/YY, HH:mm:ss")}</td>
                        <td>{e.log}</td>
                        <td>{e.forwardedForAddr}</td>
                        <td>{e.method}</td>
                      </tr>
                    );
                  } else if (e.eventType === "alert") {
                    return (
                      <tr key={i}>
                        <td>Alert</td>
                        <td className="d-none d-md-table-cell">{moment(e.eventTimestamp.toDate()).format("MMMM DD YYYY, HH:mm:ss A")}</td>
                        <td className="d-table-cell d-md-none">{moment(e.eventTimestamp.toDate()).format("M/DD/YY, HH:mm")}</td>
                        <td>{e.alertType}</td>
                        <td>{e.alertDetails.Emails}</td>
                        <td>{e.alertSuccess.toString()}</td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </Table>
          <MaterialButton
            textColor="white"
            size="small"
            color="black"
            hoverColor="black"
            variant="outlined"
            width="100"
            onClick={() => getMoreCheckins(checkins[Math.min(...Object.keys(checkins))].snapshot, 10)}
          >
            {moreButtonText}
          </MaterialButton>
        </Col>
      </Row>
    </Container>
  );
};
