import React, { useEffect, useState, useReducer, createContext } from "react";
import firebaseApp from "../Firebase/firebaseApp";
import * as firestore from "../Firebase/firestore";
import Spinner from "../Components/Spinner";

export const UserContext = createContext();

const userProfileReducer = (prevState, action) => {
  let nextState = {};
  switch (action.type) {
    case "SET":
      return action.data;
    case "RESET":
      return nextState;
    case "UPDATE":
      nextState = {
        ...prevState,
        ...action.data
      };
      return nextState;
    default:
      return prevState;
  }
};

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [currentUserProfile, setCurrentUserProfile] = useReducer(userProfileReducer, {});

  const signOut = () => {
    setCurrentUser({});
    setCurrentUserProfile({ type: "RESET" });
  };

  const updateCurrentUserProfile = async userId => {
    // Update CUProfile through db call
    const userProfile = await firestore.getUserProfile(userId);
    setCurrentUserProfile({ type: "UPDATE", data: userProfile });
    return userProfile;
  };

  const createUserProfile = async (uid, userEmail) => {
    let newUserData = {
      id: uid,
      email: userEmail
    };
    await firestore.createUserProfile(newUserData);
    return await firestore.getUserProfile(uid);
  };

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, [currentUser]);

  useEffect(() => {
    const setUserProfile = async (uid, userEmail) => {
      if (uid === undefined) {
        return;
      }
      let userProfile = await firestore.getUserProfile(uid);
      !!userProfile
        ? setCurrentUserProfile({ type: "SET", data: userProfile })
        : setCurrentUserProfile({ type: "SET", data: await createUserProfile(uid, userEmail) });
      return;
    };
    currentUser && setUserProfile(currentUser.uid, currentUser.email);
  }, [currentUser]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <UserContext.Provider
      value={{
        loading,
        currentUser,
        currentUserProfile,
        setCurrentUserProfile,
        updateCurrentUserProfile,
        signOut
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
