import React, { useContext, useState } from "react";
import { Card, CardHeader, CardBody, CardText, Col } from "reactstrap";
import "animate.css/animate.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { UserContext } from "../../Store/UserProvider";
import { BillingContext } from "../../Store/BillingProvider";
import MaterialModal from "../MaterialComponents/MaterialModal";
import "./Billing.css";
import MaterialButton from "../MaterialComponents/MaterialButton";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import * as firestore from "../../Firebase/firestore"
import { Payment } from "@material-ui/icons";

export default props => {
  const { currentUserProfile } = useContext(UserContext);
  const billingContext = useContext(BillingContext);

  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectPlan = async () => {
    if (currentUserProfile.switches.length > props.plan.count) {
      setModalOpen(true);
    } else {
      // TODO: check this...
      // if (props.plan.cost === 0 && currentUserProfile.stripeCustomerId === null) {
      //   // If customer selects the free plan & is not a stripe customer
      //   // We can update them to the free plan without collecting payment or notifying stripe
      //   store.showSpinner.set(true);
      //   await firestore.updateUserProfile(currentUserProfile.id, { planId: props.plan.id });
      //   await updateCurrentUserProfile(currentUserProfile.id);
      //   billingContext.showPlans.set(false);
      //   store.showSpinner.set(false);
      // } else if (props.plan.cost === 0) {
      //   // Current stripe customer - must cancel stripe plan but no need to collect pmt info
      //   store.showSpinner.set(true);
      //   await stripeApi.deleteStripeSubscription(currentUserProfile.stripeSubscriptionId);
      //   // const freePlan = await firestore.getPlan("cost", 0);
      //   firestore.updateUserProfile(currentUserProfile.id, { stripeSubscriptionId: null, stripePlanId: null, planId: props.plan.id });
      //   await updateCurrentUserProfile(currentUserProfile.id);
      //   billingContext.showPlans.set(false);
      //   store.showSpinner.set(false);
      // } else
      // user selects new plan, get pmt info
      billingContext.showPayment.set(true);
      billingContext.newPlan.set(props.plan);
      // }
    }
  };

  // Checkout handler
const handleSelectPlan2 = async () => {
  if (Object.keys(currentUserProfile.subscriptions).length > 0) {
    const createPortalLink = firestore.functions.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await createPortalLink({ returnUrl: window.location.href });
    window.location.assign(data.url);
    return
  }
  // event.preventDefault();
  // document.querySelectorAll('button').forEach((b) => (b.disabled = true));
  // const formData = new FormData(event.target);
  // const selectedPrice = {
  //   price: formData.get('price'),
  // };
  // For prices with metered billing we need to omit the quantity parameter.
  // For all other prices we set quantity to 1.
  // if (prices[selectedPrice.price]?.recurring?.usage_type !== 'metered')
  //   selectedPrice.quantity = 1;
  const checkoutSession = {
    // automatic_tax: true,
    // tax_id_collection: true,
    // collect_shipping_address: true,
    // allow_promotion_codes: true,
    // line_items: [props.plan.cost],
    // success_url: window.location.origin,
    // cancel_url: window.location.origin,
    // metadata: {
    //   key: 'value',
    // },
    // mode: Payment,
    price: Object.keys(props.plan.prices[0])[0],
    success_url: window.location.href,
    cancel_url: window.location.href,
  };
  // For one time payments set mode to payment.
  // if (prices[selectedPrice.price]?.type === 'one_time') {
  //   checkoutSession.mode = 'payment';
  //   checkoutSession.payment_method_types = ['card', 'sepa_debit', 'sofort'];
  // }

  const docRef = await firestore.addCheckoutSession(currentUserProfile.id, checkoutSession)
  // Wait for the CheckoutSession to get attached by the extension
  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data();
    if (error) {
      // Show an error to your customer and then inspect your function logs.
      alert(`An error occured: ${error.message}`);
      // document.querySelectorAll('button').forEach((b) => (b.disabled = false));
    }
    if (url) {
      window.location.assign(url);
    }
  });
}

  const DynamicColumn = ({ children }) => {
    let Column;
    props.selectable
      ? (Column = (
          <Col xs="12" sm="6" md="6" lg="6" xl="3">
            {children}
          </Col>
        ))
      : (Column = (
          <Col xs={{ size: 12 }} sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }}>
            {children}
          </Col>
        ));
    return Column;
  };

  function isCurrentPlan(planId, subscriptions) {
    if (planId === "default" && Object.keys(subscriptions).length === 0) {
      return true
    }
    for (let sub in subscriptions) {
      if (subscriptions[sub].product.id.endsWith(planId)) {
        return true
      }
    }
    return false
  }

  return (
    <DynamicColumn>
      <Card className="plan-card">
        <CardHeader tag="h4" className="card-header">
          <div className="plan-title">
            {isCurrentPlan(props.plan.id, currentUserProfile.subscriptions) && <DoneOutlineIcon fontSize="small" className="mr-1" />}
            {props.plan.title}
          </div>
          <div className="circle">
            <div className="circle-text-big">
              <sup className="dollar-sign">$</sup>
              {props.plan.cost}
              <sup className="plus-sign">{props.plan.cost === 0 && "+"}</sup>
            </div>
            <div className="circle-text-small">/month</div>
          </div>
        </CardHeader>
        <CardBody>
          <CardText>{props.plan.description}</CardText>
          <div className="plan-info">
            {props.plan.info.map((item, i) => (
              <div className="info-container" key={i}>
                <CheckCircleIcon fontSize="small" className="circle-icon" />
                {item}
              </div>
            ))}
          </div>
          <div className="d-flex flex-column flex-grow-1 justify-content-end">
            <span className="pb-1" style={{ fontSize: "14px", color: "#0098d4", display: "block", fontWeight: 500 }}>
              {props.plan.id === billingContext.planId.get && "Current Plan"}
            </span>
            <div>
              {props.plan.id !== "default" &&
              <MaterialButton color="#1d1d1d" hoverColor="#333333" textColor="white" variant="contained" onClick={handleSelectPlan2}>
                {isCurrentPlan(props.plan.id, currentUserProfile.subscriptions) ? "Manage" : "Select Plan"}
              </MaterialButton>
              }
              {props.plan.id === "default" && isCurrentPlan(props.plan.id, currentUserProfile.subscriptions) &&
              <div>
                <CheckCircleIcon variant="contained">
                </CheckCircleIcon>
              </div>
              }
              {isCurrentPlan(props.plan.id, currentUserProfile.subscriptions) &&
              <span className="pb-1" style={{ fontSize: "14px", color: "#0098d4", display: "block", fontWeight: 500 }}>
                Currently Selected Plan
              </span>
              }
            </div>
          </div>
        </CardBody>
      </Card>
      <MaterialModal
        isOpen={modalOpen}
        setIsOpen={() => {
          setModalOpen();
        }}
        // onClose={() => setModalOpen(false)}
        title="Error"
        message="Please remove switches before downgrading plan"
        button1Text="Okay"
        open={modalOpen}
        button1Method={() => {
          setModalOpen(false);
        }}
      />
    </DynamicColumn>
  );
};
