import React from "react";

let baseLink = process.env.REACT_APP_FLIPPER_BASE_LINK;

export const WatchdogTextLink = (switchId) => {
  return baseLink + switchId;
};

export const WatchdogLink = (props) => {
  let switchLink = baseLink + props.switchId;
  if (props.switchId) {
    return (
      <a
        href={switchLink}
        className="watchdog-link"
        target="_blank"
        rel="noopener noreferrer"
        value={switchLink}
      >
        {switchLink}
      </a>
    );
  }
  return <div></div>;
};
