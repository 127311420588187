import React, { useContext, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import "./Subscriptions.css";
import * as firestore from "../../Firebase/firestore";
import * as stripeApi from "../../Stripe/stripeApi";
import { UserContext } from "../../Store/UserProvider";
import { BillingContext } from "../../Store/BillingProvider";
import { AppContext } from "../../Store/AppProvider";
import CustomerCards from "./CustomerCards";
import ResponseModal from "../ResponseModal";
import MaterialButton from "../MaterialComponents/MaterialButton";

export default props => {
  const stripe = useStripe();
  const elements = useElements();

  const { currentUserProfile, updateCurrentUserProfile } = useContext(UserContext);
  const appContext = useContext(AppContext);
  const billingContext = useContext(BillingContext);
  const [paymentMethod, setPaymentMethod] = useState("new");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  // TODO: Error handling with declined cards
  // TODO: Remove once combined w billing?

  const createStripePaymentMethod = async () => {
    try {
      const newPaymentMethodResponse = await stripeApi.createStripePaymentMethod(currentUserProfile, stripe, elements, CardElement);
      if (newPaymentMethodResponse.error && newPaymentMethodResponse.error.type === "validation_error") {
        appContext.showSpinner.set(false);
        return "validation_error";
      }
      return newPaymentMethodResponse.paymentMethod.id;
    } catch (err) {
      return handleError(err);
    }
  };

  const createStripeCustomer = async paymentMethodId => {
    try {
      const newCustomerResponse = await stripeApi.createStripeCustomer(currentUserProfile.email, paymentMethodId);
      await firestore.updateUserProfile(currentUserProfile.id, { stripeCustomerId: newCustomerResponse.data.customer.id });
      await updateCurrentUserProfile(currentUserProfile.id);
      return newCustomerResponse.data.customer.id;
    } catch (err) {
      return handleError(err);
    }
  };

  const createStripeSubscription = async (stripeCustomerId, paymentMethodId) => {
    try {
      const stripeSubscriptionResponse = await stripeApi.createStripeSubscription({
        customerId: stripeCustomerId,
        stripePlanId: billingContext.newPlan.get.stripePlanId,
        quantity: props.donation,
        paymentMethodId: paymentMethodId
      });
      firestore.updateUserProfile(currentUserProfile.id, {
        stripeSubscriptionId: stripeSubscriptionResponse.data.subscription.id,
        stripePlanId: stripeSubscriptionResponse.data.subscription.plan.id,
        stripePlanQuantity: stripeSubscriptionResponse.data.subscription.quantity,
        planId: billingContext.newPlan.get.id
      });
      await updateCurrentUserProfile(currentUserProfile.id);
      return stripeSubscriptionResponse;
    } catch (err) {
      return handleError(err);
    }
  };

  const updateStripeSubscription = async paymentMethodId => {
    try {
      const stripeSubscriptionResponse = await stripeApi.updateStripeSubscription({
        subscriptionId: currentUserProfile.stripeSubscriptionId,
        stripePlanId: billingContext.newPlan.get.stripePlanId,
        quantity: props.donation,
        paymentMethodId: paymentMethodId
      });
      await firestore.updateUserProfile(currentUserProfile.id, {
        stripeSubscriptionId: stripeSubscriptionResponse.data.subscription.id,
        stripePlanId: billingContext.newPlan.get.stripePlanId,
        stripePlanQuantity: stripeSubscriptionResponse.data.subscription.quantity,
        planId: billingContext.newPlan.get.id
      });
      await updateCurrentUserProfile(currentUserProfile.id);
      return stripeSubscriptionResponse;
    } catch (err) {
      return handleError(err);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    appContext.showSpinner.set(true);
    let paymentMethodId;
    let stripeCustomerId;
    let stripeSubscriptionResponse;

    paymentMethod === "new" && props.donation !== 0 ? (paymentMethodId = await createStripePaymentMethod()) : (paymentMethodId = paymentMethod.id);
    if (paymentMethodId === "validation_error") {
      return;
    }

    if (!currentUserProfile.stripeCustomerId) {
      stripeCustomerId = await createStripeCustomer(paymentMethodId);
    } else {
      stripeCustomerId = currentUserProfile.stripeCustomerId;
    }

    if (paymentMethod === "new" && props.donation !== 0) {
      await stripeApi.addPaymentMethodToCustomer(stripeCustomerId, paymentMethodId);
    }

    !currentUserProfile.stripeSubscriptionId
      ? (stripeSubscriptionResponse = await createStripeSubscription(stripeCustomerId, paymentMethodId))
      : (stripeSubscriptionResponse = await updateStripeSubscription(paymentMethodId));

    if (stripeSubscriptionResponse.status === 200) {
      handleSuccess();
    }
  };

  const handleSuccess = () => {
    appContext.showSpinner.set(false);
    setSuccess(true);
    setTimeout(() => {
      billingContext.showPayment.set(false);
      billingContext.showPlans.set(false);
    }, 1000);
  };

  const handleError = error => {
    setError(true);
    console.log("error", error);
    appContext.showSpinner.set(false);
    return error;
  };

  return (
    <div>
      {success || error ? (
        <ResponseModal success={success} error={error} />
      ) : (
        <form className="payment-form" onSubmit={handleSubmit}>
          {props.donation !== 0 && (
            <div>
              <CustomerCards setPaymentMethod={setPaymentMethod} paymentMethod={paymentMethod} />
              <CardSection setPaymentMethod={setPaymentMethod} />
            </div>
          )}
          <MaterialButton type="submit" color="#1d1d1d" hoverColor="#333333" textColor="white" variant="contained">
            {props.donation !== null && props.donation === 0 ? "Select Plan" : "Submit Payment"}
          </MaterialButton>
        </form>
      )}
    </div>
  );
};
