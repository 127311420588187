import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../Store/UserProvider";
import { SwitchContext } from "../../Store/SwitchProvider";
import PaymentMethod from "../PaymentMethod";
import "animate.css/animate.css";
import { getPlan, functions} from "../../Firebase/firestore";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlarmIcon from "@material-ui/icons/Alarm";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import { Container, Row, Col } from "reactstrap";
import { getStripeSubscription, getStripePaymentMethod } from "../../Stripe/stripeApi";
import MaterialButton from "../MaterialComponents/MaterialButton";
import "./Profile.css";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "120px",
    color: "white"
  }
}));

// Billing portal handler
const goToBillingPortal = async () => {
  const createPortalLink = functions.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
  const { data } = await createPortalLink({ returnUrl: window.location.href });
  window.location.assign(data.url);
};

export default () => {
  const { currentUser, currentUserProfile } = useContext(UserContext);
  const { switchData } = useContext(SwitchContext);
  const [card, setCard] = useState(null);
  const [payment, setPayment] = useState(null);
  const [userPlan, setUserPlan] = useState({});

  const classes = useStyles();

  const labelColumnXl = { size: 4, offset: 3 };
  const valueColumnXl = { size: 5 };
  const labelColumnLg = { size: 5, offset: 2 };
  const valueColumnLg = { size: 5 };
  const labelColumnSm = { size: 7, offset: 0 };
  const valueColumnSm = { size: 5 };

  useEffect(() => {
    const getUserPlan = async () => {
      const plan = await getPlan("stripePlanId", currentUserProfile.stripePlanId);
      setUserPlan(plan);
    };
    if (currentUserProfile && currentUserProfile.stripePlanId) {
      getUserPlan();
    }
  }, [currentUserProfile, currentUserProfile.stripePlanId]);

  useEffect(() => {
    const setupStripeInfo = async () => {
      try {
        const subscription = await getStripeSubscription(currentUserProfile.stripeSubscriptionId);
        subscription && setPayment((subscription.plan.amount * subscription.quantity) / 100);
        const paymentMethod = await getStripePaymentMethod(subscription.default_payment_method);
        paymentMethod && setCard(paymentMethod.card);
      } catch (e) {
        console.log("Error: ", e);
      }
    };
    if (currentUserProfile && currentUserProfile.stripeSubscriptionId) {
      setupStripeInfo();
    }
  }, [currentUserProfile, currentUserProfile.stripeSubscriptionId]);

  return (
    <div className="mt-5 animated fadeIn text-center profile-text" style={{ animationDuration: "1.3s" }}>
      <Container>
        <Row>
          <Col xs={{ size: 12, offset: 0 }} sm={{ size: 10, offset: 1 }}>
            <Container style={{ backgroundColor: "#1d1d1d" }} className="pt-4 pb-2">
              <Row>
                <Col xs="12">
                  <AccountCircleIcon className={classes.icon} />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h4 className="profile-head-text mb-3" style={{ color: "white" }}>{`${currentUser.email}`}</h4>
                </Col>
              </Row>
            </Container>
            <Container style={{ backgroundColor: "white" }} className="p-2 p-md-4">
              <Row className="d-flex align-items-center profile-row">
                <Col xs={labelColumnSm} md={labelColumnLg} xl={labelColumnXl}>
                  <div className="d-flex align-items-center">
                    <AlarmIcon fontSize="small" className="mr-1" />
                    <h6 className="d-inline mb-0">Switches:</h6>
                  </div>
                </Col>
                <Col xs={valueColumnSm} md={valueColumnLg} xl={valueColumnXl} className="text-left">
                  <Link to="/watchdogs">{Object.keys(switchData).length}</Link>
                </Col>
              </Row>
              <Row className="d-flex align-items-center profile-row">
                <Col xs={labelColumnSm} md={labelColumnLg} xl={labelColumnXl}>
                  <div className="d-flex align-items-center">
                    <DoneOutlineIcon fontSize="small" className="mr-1" />
                    <h6 className="d-inline mb-0">Plan: </h6>
                  </div>
                </Col>
                <Col xs={valueColumnSm} md={valueColumnLg} xl={valueColumnXl} className="text-left">
                  {userPlan.description ? userPlan.description : "N/A"}
                </Col>
              </Row>
              <Row className="d-flex align-items-center profile-row">
                <Col xs={labelColumnSm} md={labelColumnLg} xl={labelColumnXl} className="text-left">
                  <div className="d-flex align-items-center">
                    <MonetizationOnOutlinedIcon fontSize="small" className="mr-1" />
                    <h6 className="d-inline mb-0">Monthly Charge: </h6>
                  </div>
                </Col>
                <Col xs={valueColumnSm} md={valueColumnLg} xl={valueColumnXl} className="text-left">
                  {typeof payment === "number" ? "$" + payment.toFixed(2) : "N/A"}
                </Col>
              </Row>
              <Row className="d-flex align-items-center profile-row">
                <Col xs={labelColumnSm} md={labelColumnLg} xl={labelColumnXl} className="text-left">
                  <div className="d-flex align-items-center">
                    <CreditCardOutlinedIcon fontSize="small" className="mr-1" />
                    <h6 className="d-inline mb-0">Payment Method: </h6>
                  </div>
                </Col>
                <Col xs={valueColumnSm} md={valueColumnLg} xl={valueColumnXl} className="text-left">
                  {card && payment !== 0 ? <PaymentMethod brand={card.brand} last4={card.last4} small /> : "N/A"}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <MaterialButton color="#1d1d1d" hoverColor="#333333" textColor="white" variant="contained" onClick={goToBillingPortal}>
          Billing Portal
        </MaterialButton>
      </Container>
    </div>
  );
};
