import React, { useContext } from "react";
import Usage from "./Usage";
import { UserContext } from "../../Store/UserProvider";
import { SwitchContext } from "../../Store/SwitchProvider";
import { useParams } from "react-router-dom";
import WatchdogEditor from "./WatchdogEditor";
import WatchdogEvents from "./WatchdogEvents";
import Spinner from "../Spinner";

export default () => {
  const { currentUserProfile } = useContext(UserContext);
  const { switchData } = useContext(SwitchContext);

  let { switchId } = useParams();
  if (!switchData[switchId]) {
    return <Spinner />;
  }

  return (
    <div>
      <Usage switchId={switchId} />
      <WatchdogEditor existing={true} switchId={switchId} switch={switchData[switchId]} active={switchData[switchId].active} />
      <WatchdogEvents userId={currentUserProfile.id} switchId={switchId} />
    </div>
  );
};
