import React from "react";

export default props => {
  return (
    <div>
      {props.success && <div style={{ color: "green" }}>Success!</div>}
      {props.error && <div style={{ color: "red" }}>Error :(</div>}
    </div>
  );
};
