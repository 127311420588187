import React, { useContext, useState, useEffect } from "react";
import PaymentMethod from "../PaymentMethod";
import { UserContext } from "../../Store/UserProvider";
import { getStripeCustomerPaymentMethods } from "../../Stripe/stripeApi";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from "reactstrap";

export default props => {
  const { currentUserProfile } = useContext(UserContext);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPaymentMethods = async () => {
      const pmtMethods = await getStripeCustomerPaymentMethods(currentUserProfile.stripeCustomerId);
      setPaymentMethods(pmtMethods);
      setIsLoading(false);
    };
    currentUserProfile.stripeCustomerId ? getPaymentMethods() : setIsLoading(false);
  }, [currentUserProfile.stripeCustomerId]);

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  const handleChange = event => {
    props.setPaymentMethod(event.target.value);
  };

  const classes = useStyles();

  const renderPaymentMethod = (paymentMethod, i) => {
    return (
      <MenuItem value={paymentMethod} style={{ display: "flex", justifyContent: "center" }} key={i}>
        <PaymentMethod
          brand={paymentMethod.card.brand}
          last4={paymentMethod.card.last4}
          expMonth={paymentMethod.card.exp_month}
          expYear={paymentMethod.card.exp_year}
          offset
        />
      </MenuItem>
    );
  };

  return isLoading === false ? (
    paymentMethods.length > 0 && (
      <div>
        {<h6>Use Existing Payment Method: </h6>}
        <FormControl className={classes.formControl}>
          <Select value={props.paymentMethod !== "new" ? props.paymentMethod : ""} onChange={handleChange}>
            {paymentMethods.map((paymentMethod, i) => renderPaymentMethod(paymentMethod, i))}
          </Select>
        </FormControl>
        <div style={{ margin: "30px" }}>
          <h6>- OR -</h6>
        </div>
      </div>
    )
  ) : (
    <Spinner className="spinner" size="xl" />
  );
};
