import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import WatchdogActiveButton from "../WatchdogActiveButton";
import DeleteSwitchButton from "./DeleteSwitchButton";
import Tooltip from "../../MaterialComponents/MaterialTooltip";
import IconButton from "@material-ui/core/IconButton";
import "./SwitchTable.css";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import moment from "moment";

const useStyles = makeStyles(() => ({
  icon: {
    color: "black"
  }
}));

export const secondsToMinutes = (s) => {
  return Math.floor(s / 60);
}

export default props => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <tr key={props.switchId} style={{ cursor: "pointer" }}>
      <td className={`vertical-center td-padding`} style={{ wordBreak: "break-all" }}>
        {props.switch.name}
      </td>
      <td className="vertical-center td-padding d-none d-md-table-cell">{secondsToMinutes(props.switch.interval)}</td>
      <td className="vertical-center td-padding d-none d-xl-table-cell">
        {props.switch.lastCheckinTimestamp ? moment(props.switch.lastCheckinTimestamp.toDate()).format("MMMM DD YYYY, HH:mm:ss A") : "Never"}
      </td>
      <td className="vertical-center td-padding d-table-cell d-xl-none">
        {props.switch.lastCheckinTimestamp ? moment(props.switch.lastCheckinTimestamp.toDate()).format("M/DD/YY, HH:mm") : "Never"}
      </td>
      <td className="vertical-center td-padding d-none d-md-table-cell">
        {props.switch.emails &&
          props.switch.emails.map((email, i) => (
            <span key={i}>
              {email}
              {i < props.switch.emails.length - 1 && ", "}
            </span>
          ))}
      </td>
      <td className="vertical-center td-padding">
        <Tooltip text="Edit Switch">
          <IconButton size="small" aria-label="edit" onClick={() => history.push("/watchdogs/watchdog/" + props.switchId)}>
            <EditOutlinedIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </td>
      <td className="vertical-center td-padding">
        <DeleteSwitchButton switchId={props.switchId} />
      </td>
      <td className={`vertical-center td-padding`}>
        <WatchdogActiveButton switchId={props.switchId} active={props.switch.active} />
      </td>
    </tr>
  );
};
