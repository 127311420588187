import React from "react";

export default props => {
  const renderCardLogo = brand => {
    let imgSrc = "";
    try {
      imgSrc = require(`../images/Card Logos Light/${brand}.png`);
    } catch (err) {
      imgSrc = require(`../images/Card Logos Light/card.png`);
    }
    return (
      <img
        style={{ height: props.small ? "20px" : "25px", marginRight: "10px", marginLeft: props.offset ? "10px" : "0px" }}
        src={imgSrc}
        title={brand}
        alt="credit card logo"
      ></img>
    );
  };

  return (
    <div>
      {renderCardLogo(props.brand)} ***
      {props.last4} {props.expMonth && props.expYear && `Exp: ${props.expMonth}/${props.expYear}`}
    </div>
  );
};
