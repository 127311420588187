export const firebaseConfig = {
  apiKey: "AIzaSyDtSK6HGHGmowfVBFwd83ZKEMSe-adpBF8",
  authDomain: "watchdogger.io",
  projectId: "watchdogger-pr2",
  storageBucket: "watchdogger-pr2.appspot.com",
  messagingSenderId: "22906757826",
  appId: "1:22906757826:web:f2a67d9eb1011e5e35515b"
};

export const firebaseRoles = ["owner", "writer", "reader"];
