import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { UserContext } from "../../Store/UserProvider";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(UserContext);
  return (
    <Switch>
      <Route {...rest} render={routeProps => (!!currentUser ? <RouteComponent {...routeProps} /> : <Redirect to={"/splash"} />)} />
    </Switch>
  );
};

export default PrivateRoute;
