import React, { useState } from "react";

export const AppContext = React.createContext({});

export const AppProvider = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const store = {
    showSpinner: { get: showSpinner, set: setShowSpinner }
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};
