import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../../Store/UserProvider";
import { SwitchContext } from "../../../Store/SwitchProvider";
import Tooltip from "../../MaterialComponents/MaterialTooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import MaterialModal from "../../MaterialComponents/MaterialModal";
import { deleteSwitch } from "../../../Firebase/switch";

const useStyles = makeStyles(() => ({
  icon: {
    color: "black"
  }
}));

export default props => {
  const { currentUserProfile, updateCurrentUserProfile } = useContext(UserContext);
  const { switchDispatch } = useContext(SwitchContext);
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  //TODO: Are you sure? upon delete

  const createSwitchDelete = async switchId => {
    if (!!currentUserProfile) {
      switchDispatch({ type: "DELETE", id: switchId });
      await deleteSwitch(currentUserProfile.id, switchId).catch(err => {
        console.log(err);
        //TODO: Handle a failure to delete the switch
        // switchDispatch({type: "reconcile"})
      });
      updateCurrentUserProfile(currentUserProfile.id);
    }
  };

  const handleClickDelete = () => {
    setModalOpen(true);
  };

  return (
    <div>
      <Tooltip text="Delete Switch">
        <IconButton size="small" aria-label="delete" onClick={handleClickDelete}>
          <DeleteForeverOutlinedIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <MaterialModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title="Delete Switch"
        message={`Are you sure you want to delete switch ${props.switchId}`}
        button1Text="Delete"
        button1Method={() => {
          createSwitchDelete(props.switchId);
          setModalOpen(false);
        }}
        button2Text="Cancel"
        button2Method={() => setModalOpen(false)}
      />
    </div>
  );
};
