import axios from "axios";
import { stripeBackendUri } from "../Config/stripeConfig"

// TODO: Why does this go stripe vs. others have to go through functions ?

export const getStripePaymentMethod = async paymentMethodId => {
  const paymentMethodResponse = await axios
    .get(stripeBackendUri + `/paymentMethods/${paymentMethodId}`, {
      headers: { "Content-Type": "application/json" }
    })
    .catch(error => {
      return error;
    });
  const response = paymentMethodResponse.data && paymentMethodResponse.data.paymentMethod;
  return response;
};

export const getStripeCustomerPaymentMethods = async customerId => {
  const paymentMethodsResponse = await axios
    .get(stripeBackendUri + `/customers/${customerId}/paymentMethods`, {
      headers: { "Content-Type": "application/json" }
    })
    .catch(error => {
      return error;
    });
  const response = paymentMethodsResponse.data ? paymentMethodsResponse.data.paymentMethods.data : [];
  return response;
};

export const createStripePaymentMethod = async (user, stripe, elements, CardElement) => {
  const response = await stripe
    .createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: user.email
      }
    })
    .catch(error => {
      return error;
    });
  return response;
};

export const addPaymentMethodToCustomer = async (customerId, paymentMethodId) => {
  const response = await axios
    .post(
      stripeBackendUri + `/customers/${customerId}/paymentMethods/${paymentMethodId}`,
      {},
      {
        headers: { "Content-Type": "application/json" }
      }
    )
    .catch(error => {
      return error;
    });
  return response;
};

export const createStripeCustomer = async (email, paymentMethodId) => {
  const body = {
    email: email,
    paymentMethodId: paymentMethodId
  };
  const response = await axios
    .post(stripeBackendUri + "/customers", body, {
      headers: { "Content-Type": "application/json" }
    })
    .catch(error => {
      return error;
    });
  return response;
};

export const createStripeSubscription = async ({ customerId, stripePlanId, quantity, paymentMethodId }) => {
  let body = {
    customerId: customerId,
    stripePlanId: stripePlanId,
    quantity: quantity,
    paymentMethodId: paymentMethodId
  };
  const response = await axios
    .post(stripeBackendUri + "/subscriptions", body, {
      headers: { "Content-Type": "application/json" }
    })
    .catch(error => {
      return error;
    });
  return response;
};

export const updateStripeSubscription = async ({ subscriptionId, stripePlanId, quantity, paymentMethodId }) => {
  let params = `?stripePlanId=${stripePlanId}`;
  if (paymentMethodId) {
    params = params.concat(`&paymentMethodId=${paymentMethodId}`);
  }
  if (typeof quantity === "number") {
    params = params.concat(`&quantity=${quantity}`);
  }
  const response = await axios
    .patch(
      stripeBackendUri + `/subscriptions/${subscriptionId}${params}`,
      {},
      {
        headers: { "Content-Type": "application/json" }
      }
    )
    .catch(error => {
      return error;
    });
  return response;
};

export const deleteStripeSubscription = async subscriptionId => {
  const response = await axios
    .delete(
      stripeBackendUri + `/subscriptions/${subscriptionId}`,
      {},
      {
        headers: { "Content-Type": "application/json" }
      }
    )
    .catch(error => {
      return error;
    });
  return response;
};

export const getStripeSubscription = async subscriptionId => {
  const response = await axios
    .get(stripeBackendUri + `/subscriptions/${subscriptionId}`, {
      headers: { "Content-Type": "application/json" }
    })
    .catch(error => {
      return error;
    });
  return response.data.subscription;
};
