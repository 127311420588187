import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import NavBar from "./NavBar";
import NavbarPadding from "./NavbarPadding";
import Auth from "./Auth/Auth";
import SplashPage from "./Auth/SplashPage";
import Profile from "./Profile/Profile";
import Billing from "./Billing/Billing";
import Watchdog from "./Watchdogs/Watchdog";
import Watchdogs from "./Watchdogs/Watchdogs";
import WatchdogEditor from "./Watchdogs/WatchdogEditor";
import Logo from "./Logo";
import Spinner from "./Spinner";
import Footer from "./Footer";
import PrivateRoute from "./Routers/PrivateRoute";
import PublicRoute from "./Routers/PublicRoute";
import "./Common.css";
import { UserProvider } from "../Store/UserProvider";
import { AppProvider } from "../Store/AppProvider";
import { SwitchProvider } from "../Store/SwitchProvider";
import { BillingProvider } from "../Store/BillingProvider";

export default () => {
  return (
    <div className="application">
      <AppProvider>
        <UserProvider>
          <Router>
            <NavBar />
            <div style={{ flexGrow: "1", display: "flex", flexDirection: "column" }}>
              <PrivateRoute path="/" component={NavbarPadding} />
              <PublicRoute exact path="/" component={Logo} />
              <PublicRoute path="/splash" component={SplashPage} />
              <PublicRoute path="/auth" component={Auth} />
              <BillingProvider>
                <PrivateRoute exact path="/billing" component={Billing} />
              </BillingProvider>
              <SwitchProvider>
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/watchdogs" component={Watchdogs} />
                <PrivateRoute exact path="/watchdogs/new" component={WatchdogEditor} />
                <PrivateRoute path="/watchdogs/watchdog/:switchId" component={Watchdog} />
                <PrivateRoute exact path="/">
                  <Redirect to="/watchdogs" />
                </PrivateRoute>
              </SwitchProvider>
              <div style={{ height: "40px" }} />
            </div>
            <Footer />
          </Router>
          <Spinner />
        </UserProvider>
      </AppProvider>
    </div>
  );
};
