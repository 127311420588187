import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import MaterialButton from "../MaterialComponents/MaterialButton";

export default () => {
  return (
    <div className="d-flex flex-column justify-content-center flex-grow-1" style={{ backgroundColor: "black" }}>
      <Logo />
      <div className="center" style={{ backgroundColor: "black" }}>
        <h6 style={{ color: "white" }}>Need a watch dog?</h6>
        <Link to="/auth?method=signup" className="link-button">
          <MaterialButton textColor="white" size="medium" color="#0098d4" hoverColor="#006c96" variant="contained" width="100">
            Sign Up
          </MaterialButton>
        </Link>{" "}
        <Link to="/auth?method=login" className="link-button">
          <MaterialButton textColor="white" size="medium" color="#0098d4" hoverColor="#006c96" variant="contained" width="100">
            Log In
          </MaterialButton>
        </Link>
      </div>
    </div>
  );
};
