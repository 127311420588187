import firebaseApp from "./firebaseApp";
import 'firebase/compat/functions'
import { functionLocation } from "../Config/stripeConfig";

const db = firebaseApp.firestore();
export const functions = firebaseApp.functions(functionLocation);

export const getPlans2 = async () => {
  const planResponse = await db
    .collection("products")
    .where("active", "==", true)
    .orderBy("stripe_metadata_order")
    .get();
  const plans = planResponse.docs.map(doc => {
    const data = doc.data();
    const id = doc.id;
    return { id, ...data };
  });
  return plans;
};

// plans:
export const getPlans = async () => {
  let planResponse = await db
    .collection("products")
    .where("active", "==", true)
    .orderBy("stripe_metadata_order")
    .get();

    let plans = planResponse.docs.map(async planDoc => {
      const data = planDoc.data();
      const id = planDoc.id;
      const prices = []

      const priceSnaps = await planDoc.ref
        .collection('prices')
        .where('active', '==', true)
        .orderBy('unit_amount')
        .get()

      priceSnaps.docs.forEach(priceDoc => {
        const priceId = priceDoc.id;
        const priceData = priceDoc.data();
        prices.push({[priceId]: priceData});
      })

      data.prices = prices
      return { id, ...data };

    })

  return Promise.all(plans);
};

export const getPlan = async (field, value) => {
  const planResponse = await db
    .collection("products")
    .where(field, "==", value)
    .get();
  const plan = planResponse.docs.map(doc => {
    const data = doc.data();
    const id = doc.id;
    return { id, ...data };
  })[0];
  return plan;
};

// userProfile:
export const getUserProfile = async userId => {
  if (userId === undefined) {
    return false;
  }
  const doc = await db
    .collection("userProfile")
    .doc(userId)
    .get();
  let data = doc.data();
  let id = doc.id;
  if (!doc.exists) {
    return false;
  }
  let subscriptions = {};
  const subscriptionSnaps = await db
    .collection("userProfile")
    .doc(userId)
    .collection("subscriptions")
    .where('status', 'in', ['trialing', 'active'])
    .get();
  subscriptionSnaps.docs.forEach(subDoc => {
    const subId = subDoc.id;
    const subData = subDoc.data();
    subscriptions[subId] = subData;
  });
  data["subscriptions"] = subscriptions;
  return { ...data, id };
};

export const subscribeUserProfile = (id, onUpdate) => {
  return db
    .collection("userProfile")
    .doc(id)
    .onSnapshot(
      doc => {
        var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        onUpdate(source, doc.id, doc.data());
      },
      err => {
        throw new Error("Error subscribing: " + err);
      }
    );
};

export const updateUserProfile = async (userProfileId, data) => {
  await db
    .collection("userProfile")
    .doc(userProfileId)
    .update(data);
};

export const createUserProfile = async data => {
  await db
    .collection("userProfile")
    .doc(data.id)
    .set(data);
};

// User Profile Payment Methods
export const getUserProfilePaymentMethods = async userProfileId => {
  const paymentMethodResponse = await db
    .collection("userProfile")
    .doc(userProfileId)
    .collection("paymentMethod")
    // .where("uid", "==", userProfileId)
    .get();
  const paymentMethods = paymentMethodResponse.docs.map(doc => {
    const data = doc.data();
    const id = doc.id;
    return { ...data, id };
  });
  return paymentMethods;
};

export const addPaymentMethodCollectionToUserProfile = async (userId, paymentMethod) => {
  await db
    .collection("userProfile")
    .doc(userId)
    .collection("paymentMethod")
    .doc(paymentMethod.id)
    .set(paymentMethod);
};

export const addCheckoutSession = async (userId, checkoutSession) => {
  return db
    .collection('userProfile')
    .doc(userId)
    .collection('checkout_sessions')
    .add(checkoutSession);
}