import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../Store/UserProvider";
import { SwitchContext } from "../../Store/SwitchProvider";
import { useHistory } from "react-router-dom";
import { Container, Col, Row, Form, FormGroup, Label, Input } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import "./Watchdogs.css";
import "animate.css/animate.css";
import Tooltip from "../MaterialComponents/MaterialTooltip";
import IconButton from "@material-ui/core/IconButton";
import MaterialButton from "../MaterialComponents/MaterialButton";
import WatchdogActiveButton from "./WatchdogActiveButton";
import MaterialModal from "../MaterialComponents/MaterialModal";
import { secondsToMinutes } from "./SwitchTable/SwitchRow";
import { checkSwitchEntitlement, newSwitch, editSwitch } from "../../Firebase/switch"

const useStyles = makeStyles(() => ({
  icon: {
    cursor: "pointer",
    color: "green",
    display: "inline"
  }
}));

export default props => {
  const history = useHistory();
  const classes = useStyles();

  const { currentUserProfile } = useContext(UserContext);
  const { switchDispatch } = useContext(SwitchContext);

  const [formValues, setFormValues] = useState(
    props.existing ? { ...props.switch, interval: secondsToMinutes(props.switch.interval) } : { name: "", interval: "", emails: [""], description: "", active: "" }
  );
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleChangeEmail = (e, emails, i) => {
    const tempEmails = formValues.emails.slice();
    tempEmails[i] = e.target.value;
    setFormValues({ ...formValues, emails: tempEmails });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (props.existing) {
      createSwitchEdit(formValues.name, minutesToSeconds(formValues.interval), splitEmails(formValues.emails), formValues.description, () => {
        setModalMessage("Switch successfully updated");
        setModalTitle("Success");
        setModalOpen(true);
      });
    } else {
      if (await checkSwitchEntitlement(currentUserProfile.id)){
        createNewSwitch(formValues.name, minutesToSeconds(formValues.interval), splitEmails(formValues.emails), formValues.description, () => {
          setModalMessage("Switch successfully created");
          setModalTitle("Success");
          setModalOpen(true);
        });
      } else {
        alert("You have reached the maximum number of switches, please upgrade to add more.")
        history.push("/billing");
      }
    };
  };

  const addEmail = () => {
    formValues.emails.push("");
    setFormValues({ ...formValues, emails: formValues.emails });
  };

  const removeEmail = i => {
    formValues.emails.splice(i, 1);
    setFormValues({ ...formValues, emails: formValues.emails });
  };

  const splitEmails = emails => {
    if (typeof emails === "string") {
      return emails.split(",");
    }
    return emails;
  };

  const minutesToSeconds = (m) => {
    return m * 60;
  }

  const createNewSwitch = (name, interval, emails, description, onSuccess) => {
      newSwitch(currentUserProfile.id, name, interval, emails, description)
      .then(([id, s]) => {
        switchDispatch({ type: "ADD", id: id, data: s });
        onSuccess();
      })
      .catch(err => {
        setModalMessage("Error: There was a problem creating the new Watchdog.");
        setModalTitle("Error");
        setModalOpen(true);
        console.log(err);
      });
  };

  const createSwitchEdit = (name, interval, emails, description, callback) => {
      editSwitch(props.switchId, props.active, name, interval, emails, description)
      .then(resp => {
        switchDispatch({ type: "UPDATE", id: resp.id, data: resp.data() });
        callback();
      })
      .catch(err => {
        console.log(err);
        setModalMessage("Error: There was a problem editing the Watchdog.");
        setModalTitle("Error");
        setModalOpen(true);
        console.log(err);
      });
  };

  return (
    <Container>
      <Row className="animated fadeIn mt-5" style={{ animationDuration: "1.3s" }}>
        <Col xs={{ size: "12", offset: "0" }} md={{ size: "10", offset: "1" }} lg={{ size: "8", offset: "2" }}>
          <div className="watchdog-form-container">
            <Container>
              <Row>
                <Col xs="12" style={{ textAlign: "center" }}>
                  <h3>{props.existing ? "Update Watchdog" : "Create Watchdog"}</h3>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Form onSubmit={handleSubmit}>
                    <Container>
                      <Row form>
                        <Col xs="12" md="7">
                          <FormGroup>
                            <Label className="form-label d-flex align-items-center" for="name">
                              Watchdog Name:
                            </Label>
                            <Input
                              className="form-input"
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Watchdog Name"
                              value={formValues.name}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="8" md="3">
                          <FormGroup>
                            <Label className="form-label d-flex align-items-center" for="interval">
                              Interval (minutes):
                            </Label>
                            <Input
                              className="form-input"
                              type="number"
                              min="0"
                              name="interval"
                              id="interval"
                              placeholder="Interval"
                              value={formValues.interval}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        {props.existing && (
                          <Col xs="4" md="2">
                            <FormGroup>
                              <Label className="form-label d-flex align-items-center" for="interval">
                                Active:
                              </Label>
                              {<WatchdogActiveButton switchId={props.switchId} active={props.active} label={true} />}
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row form>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label className="form-label d-flex align-items-center" for="emails">
                              Emails:
                              <Tooltip text="Add Email">
                                <IconButton
                                  aria-label="edit"
                                  style={{ width: "8px", height: "8px", padding: "0", paddingLeft: "15px" }}
                                  className="remove-outline"
                                  onClick={addEmail}
                                >
                                  <AddIcon className={classes.icon} />
                                </IconButton>
                              </Tooltip>
                            </Label>
                            {formValues.emails.map((email, i) => (
                              <div key={i} style={{ display: "flex", alignItems: "center", paddingBottom: "3px" }}>
                                <Input
                                  className="form-input"
                                  type="email"
                                  name="emails"
                                  id="emails"
                                  placeholder="Email"
                                  value={formValues.emails[i]}
                                  onChange={e => handleChangeEmail(e, formValues.emails, i)}
                                  style={{ display: "inline", flexGrow: "1" }}
                                />
                                {formValues.emails.length > 1 && (
                                  <Tooltip text="Remove Email">
                                    <IconButton
                                      size="small"
                                      aria-label="edit"
                                      className="remove-outline"
                                      onClick={() => removeEmail(i)}
                                      fontSize="small"
                                      style={{ color: "#dc0000", cursor: "pointer" }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            ))}
                          </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label className="form-label d-flex align-items-center" for="description">
                              Description:
                            </Label>
                            <Input
                              className="form-input"
                              type="text"
                              name="description"
                              id="description"
                              placeholder="Description"
                              value={formValues.description}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <MaterialButton
                            type="submit"
                            textColor="white"
                            size="medium"
                            color="#1d1d1d"
                            hoverColor="#333333"
                            variant="contained"
                            width="100"
                          >
                            {props.existing ? "UPDATE WATCHDOG" : "CREATE WATCHDOG"}
                          </MaterialButton>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
      <MaterialModal
        isOpen={modalOpen}
        setIsOpen={() => {
          setModalOpen();
        }}
        title={modalTitle}
        message={modalMessage}
        button1Text="Okay"
        open={modalOpen}
        button1Method={() => {
          history.push("/watchdogs");
          setModalOpen(false);
        }}
      />
    </Container>
  );
};
