import firebaseApp from "./firebaseApp";
import firebase from "firebase/compat/app";
import { functions } from "./firestore";
import { firebaseRoles } from "./firebaseConfig";

const db = firebaseApp.firestore();

// this allows for local testing of rules
// firebase emulators:start --only firestore
// db.useEmulator("127.0.0.1", 8080);

const switchCollection = db.collection("monitors");

const validateFields = (userId, active, name, interval, emails, description) => {
  if (
    typeof userId !== "string" ||
    typeof active !== "boolean" ||
    typeof interval !== "number" ||
    emails.constructor !== Array ||
    typeof name !== "string" ||
    typeof description !== "string"
  ) {
    throw new Error("Error: userId, interval, name, description must be strings, emails must be array.");
  }
}


export const getSwitch = async switchId => {
    return switchCollection
      .doc(switchId)
      .get();
};

// TODO: Fetch  the user switch data
export const getSwitches = async userId => {
  let switches = {};
  let count = 0;
  return new Promise(async (resolve, reject) => {
    await switchCollection
      .where("roles." + userId, "in", firebaseRoles)
      .get()
      .then(async querySnapshot => {
        count = querySnapshot.size;
        querySnapshot.forEach(async doc => {
          let sd = await doc.data();
          count--;
          switches[doc.id] = sd;
          if (count === 0) {
            resolve(switches);
          }
        });
      })
      .catch(err => reject(err));
  });
};



export const subscribeSwitches = (userId, onUpdate) => {
  return switchCollection
    .where("roles." + userId, "in", firebaseRoles)
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        onUpdate(doc.id, doc.data());
      });
    });
};

export const checkSwitchEntitlement = async (userId) => {
  let userRef = db.collection("userProfile").doc(userId);
  let user = await userRef.get();
  if (!user.exists) {
    throw new Error("Error: customer of id does not exist.");
  }

  let userData = user.data();
  let subscriptionSnaps = await userRef.collection("subscriptions")
    .where('status', 'in', ['trialing', 'active'])
    .get();

  let maxSwitches = 0;

  if (subscriptionSnaps.size === 0) {
    let defaultSub = await db.collection("products").doc("default").get();
    let defaultSubData = defaultSub.data();
    maxSwitches = defaultSubData.metadata.entitlement_switches;
  } else {
    subscriptionSnaps.docs.forEach(subDoc => {
      const subData = subDoc.data();
      subData.items.forEach(item => {
        maxSwitches += parseInt(item.price.product.metadata.entitlement_switches, 10)
      })
    });
  }

  if (userData.switches.length >= maxSwitches) {
    return false
  }
  return true
}

export const newSwitch = async (userId, name, interval, emails, description) => {
    try {
        validateFields(userId, true, name, interval, emails, description);
    } catch (e) {
        return e
    }

  const newUID = await functions.httpsCallable("newUID")();
  const id = newUID.data.id;

  let s = {
    id: id,
    type: "switch",
    roles: {
        [userId]: "owner"
    },
    name: name,
    interval: interval,
    lastAlertTimestamp: firebase.firestore.Timestamp.fromDate(new Date(0)),
    description: description,
    emails: emails,
    active: true,
    dirty: true // queried on backend to find configurations that need to be updated
  };

  return new Promise(async (resolve, reject) => {
    switchCollection.doc(id).set(s)
      .then(() => resolve([id, s]))
      .catch(err => reject(err));
  })
};

export const editSwitch = async (switchId, active, name, interval, emails, description) => {
  try {
      validateFields("", active, name, interval, emails, description);
  } catch (e) {
      return e
  }

  return new Promise(async (resolve, reject) => {
    switchCollection.doc(switchId).update({
      name: name,
      interval: interval,
      description: description,
      emails: emails,
      active: active,
      dirty: true // queried on backend to find configurations that need to be updated
    })
    .then(() => {
      resolve({
        id: switchId,
        data: () => {
          return {
            name: name,
            active: active,
            interval: interval,
            emails: emails,
            description: description
          };
        }
      });
    }).catch(err => reject(err));
  });
};

export const deleteSwitch = async (userId, switchId) => {
  if (typeof userId !== "string" || typeof switchId !== "string") {
    throw new Error("Error: userId and switchId must be strings.");
  }
  return await switchCollection.doc(switchId).delete()
};


export const setSwitchActive = async (userId, switchId, active) => {
  let a = active ? true : false; //makes sure we are writing a bool to db
  let switchRef = await switchCollection.doc(switchId);
  return await switchRef.update({"active": a, "dirty": true});
};

export const subscribeAlerts = (userId, switchId, onUpdate) => {
  return switchCollection
    .doc(switchId)
    .collection("alert")
    .where("uid", "==", userId)
    .orderBy("eventTimestamp", "desc")
    .limit(1)
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        onUpdate(doc.id, { snapshot: doc, ...doc.data() });
      });
    });
};

export const getAlerts = async (userId, switchId, startAfterTime) => {
  let alerts = {};
  let count = 0;
  return new Promise(async (resolve, reject) => {
    await switchCollection
      .doc(switchId)
      .collection("alert")
      .orderBy("eventTimestamp", "desc")
      .where("uid", "==", userId)
      .where("eventTimestamp", ">=", startAfterTime)
      .get()
      .then(async querySnapshot => {
        count = querySnapshot.size;
        querySnapshot.forEach(async doc => {
          alerts[doc.id] = { snapshot: doc, ...doc.data() };
          count--;
        });
        if (count === 0) {
          resolve(alerts);
        }
      })
      .catch(err => reject(err));
  });
};

export const subscribeCheckins = (switchId, onUpdate) => {
  return switchCollection
    .doc(switchId)
    .collection("checkin")
    .orderBy("eventTimestamp", "desc")
    .limit(1)
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        onUpdate(doc.id, { snapshot: doc, ...doc.data() });
      });
    });
};

export const getCheckins = async (switchId, limit = 10, startAfter = null) => {
  let checkins = {};
  let count = 0;
  return new Promise(async (resolve, reject) => {
    if (!!startAfter) {
      await switchCollection
        .doc(switchId)
        .collection("checkin")
        .orderBy("eventTimestamp", "desc")
        .startAfter(startAfter)
        .limit(limit)
        .get()
        .then(async querySnapshot => {
          count = querySnapshot.size;
          querySnapshot.forEach(async doc => {
            checkins[doc.id] = { snapshot: doc, ...doc.data() };
            count--;
          });
          if (count === 0) {
            resolve(checkins);
          }
        })
        .catch(err => reject(err));
    } else {
      await switchCollection
        .doc(switchId)
        .collection("checkin")
        .orderBy("eventTimestamp", "desc")
        .limit(limit)
        .get()
        .then(async querySnapshot => {
          count = querySnapshot.size;
          querySnapshot.forEach(async doc => {
            checkins[doc.id] = { snapshot: doc, ...doc.data() };
            count--;
          });
          if (count === 0) {
            resolve(checkins);
          }
        })
        .catch(err => reject(err));
    }
  });
};
