import React, { useContext } from "react";
import { SwitchContext } from "../../Store/SwitchProvider";
import SwitchTable from "./SwitchTable/SwitchTable";
import { Container, Row, Col } from "reactstrap";
import "animate.css/animate.css";

export default () => {
  const { switchData } = useContext(SwitchContext);
  return (
    <div className="animated fadeIn" style={{ animationDuration: "1.3s", paddingTop: "20px" }}>
      <Container>
        <Row>
          <Col>{!!switchData && <SwitchTable switchData={switchData} />}</Col>
        </Row>
      </Container>
    </div>
  );
};
