import React, { useContext } from "react";
import { UserContext } from "../Store/UserProvider";

export default () => {
  const { currentUser } = useContext(UserContext);

  const backgroundColor = currentUser ? "#e0e0e0" : "black";
  const textColor = currentUser ? "#1d1d1d" : "#212529";

  return (
    <div
      style={{
        width: "100vw",
        height: "40px",
        position: "fixed",
        bottom: "0",
        display: "flex",
        alignItems: "center",
        backgroundColor: backgroundColor
      }}
    >
      <div style={{ height: "2px", backgroundColor: textColor, margin: "6px 10px 0 10px", width: "100%" }}></div>
      <div style={{ color: "#1b4973", marginRight: "10px" }}>
        <span className="bold-logo-text" style={{ fontSize: "25px", color: textColor }}>
          watch
        </span>
        <span className="light-logo-text" style={{ fontSize: "25px", color: textColor }}>
          dogger
        </span>
      </div>
    </div>
  );
};
