import React, { useContext } from "react";
import { Container, Row } from "reactstrap";
import Plan from "./Plan";
import PaymentModal from "./PaymentModal";
import { BillingContext } from "../../Store/BillingProvider";
import "animate.css/animate.css";

export default () => {
  const billingContext = useContext(BillingContext);

  return (
    <Container>
      <div style={{ textAlign: "center", marginTop: "2vw" }}>
        <h3 className="animated fadeIn mt-2" style={{ animationDuration: "1.3s" }}>
          Billing
        </h3>
        {billingContext.plans.get.length > 0 && (
          <Row className="animated fadeIn" style={{ animationDuration: "1.3s" }}>
            {billingContext.plans.get.map((plan, i) => (
              <Plan plan={plan} selectable={true} key={i} />
            ))}
          </Row>
        )}
        <PaymentModal />
      </div>
    </Container>
  );
};
