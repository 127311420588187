import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../Store/UserProvider";
import * as firestore from "../Firebase/firestore";

export const BillingContext = React.createContext({});

export const BillingProvider = ({ children }) => {
  const { currentUserProfile } = useContext(UserContext);

  const [plan, setPlan] = useState();
  const [plans, setPlans] = useState([]);
  const [showPlans, setShowPlans] = useState(false);
  const [planId, setPlanId] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [newPlan, setNewPlan] = useState(null);

  const store = {
    plan: { get: plan, set: setPlan },
    plans: { get: plans, set: setPlans },
    showPlans: { get: showPlans, set: setShowPlans },
    showPayment: { get: showPayment, set: setShowPayment },
    setPlan: { get: plan, set: setPlan },
    planId: { get: planId, set: setPlanId },
    newPlan: { get: newPlan, set: setNewPlan }
  };

  useEffect(() => {
    setPlan(plans.find(plan => plan.id === planId));
  }, [plans, planId]);

  useEffect(() => {
    const getSetPlans = async () => {
      const plansResponse = await firestore.getPlans();
      setPlans(plansResponse);
    };
    getSetPlans();
  }, []);

  useEffect(() => {
    setPlanId(currentUserProfile.planId);
  }, [currentUserProfile.planId]);

  return <BillingContext.Provider value={store}>{children}</BillingContext.Provider>;
};
