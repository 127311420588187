import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import AuthForm from "./AuthForm";
import firebaseApp from "../../Firebase/firebaseApp";
import "./Auth.css";

const Auth = props => {
  const [isLogin, setIsLogin] = useState();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    const method = query.get("method");
    method === "login" ? setIsLogin(true) : setIsLogin(false);
  }, [query]);

  const handleEmailAuth = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        isLogin
          ? await firebaseApp.auth().signInWithEmailAndPassword(email.value, password.value)
          : await firebaseApp.auth().createUserWithEmailAndPassword(email.value, password.value);
        props.history.push("/watchdogs");
      } catch (error) {
        //TODO: Error Handling
        alert(error);
      }
    },
    [props.history, isLogin]
  );

  const handleGoogleAuth = useCallback(async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      isLogin ? await firebaseApp.auth().signInWithPopup(provider) : await firebaseApp.auth().signInWithPopup(provider);
      props.history.push("/watchdogs");
    } catch (error) {
      //TODO: Error Handling
      alert(error);
    }
  }, [props.history, isLogin]);

  return (
    <AuthForm
      formTitle={isLogin ? "Log In:" : "Sign Up:"}
      submitText={isLogin ? "Log In" : "Sign Up"}
      handleSubmit={handleEmailAuth}
      handleGoogleSubmit={handleGoogleAuth}
      googleButtonText={isLogin ? "Log In with Google" : "Sign Up with Google"}
      redirectText={isLogin ? "New to watchdogger? " : "Already a watchdogger? "}
      redirectLinkText={isLogin ? "Sign Up" : "Log In"}
      redirectHref={isLogin ? "/auth?method=signup" : "/auth?method=login"}
      isLogin={isLogin}
      setIsLogin={setIsLogin}
    />
  );
};

export default withRouter(Auth);
