import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Subscriptions.css";
import PaymentForm from "./PaymentForm";
import { stripePk } from "../../Config/stripeConfig";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripePk);

export default props => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm donation={props.donation} />
    </Elements>
  );
};
