import React, { useState, useContext, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button } from "reactstrap";
import firebaseApp from "../Firebase/firebaseApp";
import { UserContext } from "../Store/UserProvider";
import { useMediaQuery } from "react-responsive";
import MaterialModal from "./MaterialComponents/MaterialModal";
import $ from "jquery";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { currentUser, signOut } = useContext(UserContext);
  const history = useHistory();

  const isMobileMenu = useMediaQuery({ query: "(max-width: 767px)" });

  const handleClickSignout = () => {
    isMobileMenu && toggle();
    setModalOpen(true);
  };

  useEffect(() => {
    $(document).click(function(event) {
      let clickover = $(event.target);
      let $navbar = $(".navbar-collapse");
      let opened = $navbar.hasClass("show");
      if (opened === true && !clickover.hasClass("navbar-toggle")) {
        toggle();
      }
    });
  });

  const signout = async () => {
    await firebaseApp.auth().signOut();
    signOut();
  };

  const toggle = () => setMenuOpen(!menuOpen);

  return currentUser ? (
    <Navbar color="dark" dark expand="md" className="navStyle fixed-top">
      <NavbarToggler className="toggler" onClick={toggle} />
      <NavbarBrand
        className="navbar-logo mx-auto nav-logo-container d-flex d-md-none"
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        onClick={() => history.push("/watchdogs")}
      >
        <span className="bold-logo-text turquoise-text logo-font-size">watch</span>
        <span className="light-logo-text white-text logo-font-size">dogger</span>
      </NavbarBrand>
      <NavbarBrand
        className="navbar-logo mr-auto nav-logo-container d-none d-md-flex"
        style={{ alignItems: "center", justifyContent: "center" }}
        onClick={() => history.push("/watchdogs")}
      >
        <span className="bold-logo-text turquoise-text logo-font-size">watch</span>
        <span className="light-logo-text white-text logo-font-size">dogger</span>
      </NavbarBrand>
      <Collapse isOpen={menuOpen} navbar className="order-1 order-md-0">
        <Nav className="ml-auto" navbar>
          <NavItem className="link-container d-flex mr-0">
            <NavLink
              to="/watchdogs"
              style={{ textDecoration: "none", fontSize: "20px", lineHeight: "40px" }}
              activeClassName="active-nav-link"
              onClick={() => {
                isMobileMenu && toggle();
              }}
            >
              <div className="nav-thing" style={{ textAlign: "center" }}>
                <div className="d-block nav-thing-text underline-animation">watchdogs</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="link-container d-flex mr-0" style={{ lineHeight: "40px" }}>
            <NavLink
              to="/billing"
              style={{ textDecoration: "none", fontSize: "20px" }}
              activeClassName="active-nav-link"
              onClick={() => {
                isMobileMenu && toggle();
              }}
            >
              <div className="nav-thing" style={{ textAlign: "center" }}>
                <div className="d-block nav-thing-text underline-animation">billing</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="link-container d-flex mr-0" style={{ lineHeight: "40px" }}>
            <NavLink
              to="/profile"
              style={{ textDecoration: "none", fontSize: "20px" }}
              activeClassName="active-nav-link"
              onClick={() => {
                isMobileMenu && toggle();
              }}
            >
              <div className="nav-thing" style={{ textAlign: "center" }}>
                <div className="d-block nav-thing-text underline-animation">profile</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem className="link-container d-flex mr-0" style={{ lineHeight: "40px" }}>
            <Button onClick={handleClickSignout} style={{ textDecoration: "none", fontSize: "20px" }} className="nav-link-button underline-animation">
              <div className="d-block nav-link-button-text">sign out</div>
            </Button>
          </NavItem>
        </Nav>
      </Collapse>
      <MaterialModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title="Sign Out"
        message="Are You Sure?"
        button1Text="Sign Out"
        button1Method={() => {
          signout();
          setModalOpen(false);
        }}
        button2Text="Cancel"
        button2Method={() => setModalOpen(false)}
      />
    </Navbar>
  ) : (
    <div />
  );
};

export default NavBar;
