import React, { useContext } from "react";
import { Table } from "reactstrap";
import SwitchRow from "./SwitchRow";
import { useHistory } from "react-router-dom";
import "./SwitchTable.css";
import AddIcon from "@material-ui/icons/Add";
import "animate.css/animate.css";
import MaterialButton from "../../MaterialComponents/MaterialButton";
import { Spinner } from "reactstrap";
import { UserContext } from "../../../Store/UserProvider";
import { checkSwitchEntitlement } from "../../../Firebase/switch";

export default props => {
  const history = useHistory();
  const { currentUserProfile } = useContext(UserContext);

  const handleClickNewWatchdog = async () => {
    if (await checkSwitchEntitlement(currentUserProfile.id)) {
      history.push("/watchdogs/new");
    } else {
      alert("You have reached the maximum number of switches, please upgrade to add more.");
      history.push("/billing");
    }
  };

  // TODO: Error handling for no switches
  return (
    <Table borderless hover size="sm" className="table-main">
      <thead>
        <tr className="switch-tr-head">
          <th className="header-padding h-border-bottom">
            <span className="switch-tr-h">Name</span>
          </th>
          <th className="header-padding h-border-bottom d-none d-md-table-cell">
            <span className="switch-tr-h">Interval</span>
          </th>
          <th className="header-padding h-border-bottom">
            <span className="switch-tr-h">Check In</span>
          </th>
          <th className="header-padding h-border-bottom  d-none d-md-table-cell">
            <span className="switch-tr-h">Emails</span>
          </th>
          <th colSpan="3" className="header-padding h-border-bottom">
            <div style={{ width: "100%" }} className="d-flex justify-content-end">
              <MaterialButton
                textColor="white"
                size="small"
                color="#0098d4"
                hoverColor="#006c96"
                variant="contained"
                // width="100"
                onClick={handleClickNewWatchdog}
              >
                <AddIcon size="small" />
                <span className="d-none d-md-block">New Watchdog</span>
              </MaterialButton>
            </div>
          </th>
        </tr>
      </thead>
      <tbody style={{ backgroundColor: "white" }}>
        {/* TODO: Fix spinner, account for 0 watchdogs */}
        {props.switchData ? (
          Object.keys(props.switchData).map((switchId, i, allSwitches) => (
            <SwitchRow switchId={switchId} switch={props.switchData[switchId]} i={i} key={i} length={allSwitches.length} />
          ))
        ) : (
          <tr>
            <td colSpan="7" style={{ height: "30vh", textAlign: "center", verticalAlign: "middle" }}>
              <Spinner />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
