import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MaterialButton from "./MaterialButton";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import $ from "jquery";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3),
    outline: "none",
    minWidth: "40%"
  }
}));

const Header = props => (
  <div className="d-flex justify-content-between">
    <h5 className="d-inline" id="transition-modal-title">
      {props.title}
    </h5>
    <span>
      <IconButton size="small" onClick={() => props.setIsOpen(false)}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </span>
  </div>
);

export default props => {
  const classes = useStyles();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [mobileWidth, setMobileWidth] = useState({});

  useEffect(() => {
    isSmallScreen ? setMobileWidth({ width: "95%" }) : setMobileWidth({});
  }, [isSmallScreen]);

  useEffect(() => {
    let mounted = true;
    $(window).resize(() => {
      mounted && updateScreenSize();
    });
    return () => (mounted = false);
  }, []);

  const updateScreenSize = () => {
    $(window).width() > 767 ? setIsSmallScreen(false) : setIsSmallScreen(true);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.isOpen}
        onClose={() => props.setIsOpen(false)}
        closeAfterTransition
        style={{ maxWidth: "100vw" }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.isOpen}>
          <div className={classes.paper} style={{ ...props.style, ...mobileWidth }}>
            {props.children ? (
              <div>
                <Header title={props.title} setIsOpen={props.setIsOpen} />
                {props.children}
              </div>
            ) : (
              <div>
                <Header title={props.title} setIsOpen={props.setIsOpen} />
                <p id="transition-modal-description">{props.message}</p>
                <div className="d-flex justify-content-end mt-2">
                  {props.button1Text && (
                    <MaterialButton color="#1d1d1d" hoverColor="#333333" textColor="white" variant="contained" onClick={() => props.button1Method()}>
                      {props.button1Text}
                    </MaterialButton>
                  )}
                  {props.button2Text && (
                    <span className="ml-2">
                      <MaterialButton
                        color="#1d1d1d"
                        hoverColor="#333333"
                        textColor="white"
                        variant="contained"
                        onClick={() => props.button2Method()}
                      >
                        {props.button2Text}
                      </MaterialButton>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
