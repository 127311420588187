import React, { useReducer, createContext, useContext, useEffect } from "react";
import { UserContext } from "../Store/UserProvider";
import { getSwitches, subscribeSwitches } from "../Firebase/switch";

export const SwitchContext = createContext();

const switchReducer = (prevState, action) => {
  let nextState = {};
  switch (action.type) {
    case "ADD":
      Object.keys(prevState).forEach(switchId => {
        nextState[switchId] = prevState[switchId];
      });
      nextState[action.id] = action.data;
      return nextState;
    case "UPDATE":
      Object.keys(prevState).forEach(switchId => {
        nextState[switchId] = prevState[switchId];
      });
      if (!nextState[action.id]) {
        nextState[action.id] = action.data;
      } else {
        Object.keys(action.data).forEach(switchId => {
          nextState[action.id][switchId] = action.data[switchId];
        });
      }
      return nextState;
    case "SET":
      return action.data;
    case "DELETE":
      Object.keys(prevState).forEach(switchId => {
        nextState[switchId] = prevState[switchId];
      });
      delete nextState[action.id];
      return nextState;
    case "RESET":
      return nextState;
    default:
      throw new Error();
  }
};

export const SwitchProvider = ({ children }) => {
  const [switchData, switchDispatch] = useReducer(switchReducer, {});
  const { currentUserProfile } = useContext(UserContext);

  useEffect(() => {
    if (currentUserProfile.id) {
        getSwitches(currentUserProfile.id)
        .then(switches => {
          switchDispatch({ type: "SET", data: switches });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      switchDispatch({ type: "RESET" });
    }
  }, [currentUserProfile, currentUserProfile.id]);

  useEffect(() => {
    if (!!currentUserProfile.id) {
      let unsubscribe = subscribeSwitches(currentUserProfile.id, (id, data) => {
        switchDispatch({ type: "UPDATE", id: id, data: data });
      });
      unsubscribe();
    }
  }, [currentUserProfile, currentUserProfile.id]);

  return <SwitchContext.Provider value={{ switchData, switchDispatch }}>{children}</SwitchContext.Provider>;
};
