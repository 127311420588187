import React, { useContext, useState, useEffect } from "react";
import { BillingContext } from "../../Store/BillingProvider";
import { UserContext } from "../../Store/UserProvider";
import Payment from "./Payment";
import MaterialButton from "../MaterialComponents/MaterialButton";
import MaterialModal from "../MaterialComponents/MaterialModal";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import { getStripeSubscription } from "../../Stripe/stripeApi";

export default () => {
  const { currentUserProfile } = useContext(UserContext);
  const billingContext = useContext(BillingContext);
  const [donation, setDonation] = useState(4);

  const toggle = newIsOpen => {
    if (newIsOpen === true || newIsOpen === false) {
      billingContext.showPayment.set(newIsOpen);
    } else {
      billingContext.showPayment.set(!billingContext.showPayment.get);
    }
  };
  const newPlan = billingContext.newPlan.get;

  useEffect(() => {
    const setupDonation = async () => {
      if (currentUserProfile && currentUserProfile.stripeSubscriptionId) {
        const subscription = await getStripeSubscription(currentUserProfile.stripeSubscriptionId);
        if (subscription.plan.amount === 100) {
          setDonation((subscription.plan.amount / 100) * subscription.quantity);
        } else {
          setDonation(4);
        }
      }
    };
    setupDonation();
  }, [billingContext.showPayment.get, currentUserProfile]);

  const increaseDonation = () => {
    if (donation === 4) {
      return;
    } else {
      const newDonation = donation + 1;
      setDonation(newDonation);
    }
  };

  const decreaseDonation = () => {
    if (donation === 0) {
      return;
    } else {
      const newDonation = donation - 1;
      setDonation(newDonation);
    }
  };

  return (
    <div>
      <MaterialModal isOpen={billingContext.showPayment.get} title={newPlan && newPlan.title} setIsOpen={toggle}>
        <div>
          <div style={{ marginLeft: "3%", marginRight: "3%", textAlign: "center" }}>
            {/* {newPlan && newPlan.cost === 0 ? (
              <div>
                <div className="mb-3 mr-2 ml-2 font-italic">Please consider a small donation to help keep watchdogger up and running :)</div>
                <div className="mb-3">
                  <h5 className="d-inline" style={{ marginBottom: "20px" }}>
                    <span className="turquoise-text">{"$" + donation.toFixed(2)}</span> per month
                  </h5>
                  <span style={{ width: "fit-content" }}>
                    <IconButton size="small" onClick={increaseDonation}>
                      <ArrowDropUpIcon />
                    </IconButton>
                    <IconButton size="small" onClick={decreaseDonation}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </span>
                </div>
              </div>
            ) : (
              <h5 style={{ marginBottom: "20px" }}>{newPlan && "$" + newPlan.cost.toFixed(2)} per month</h5>
            )} */}
            <Payment donation={newPlan && newPlan.cost === 0 ? donation : null} />
          </div>
          <div className="d-flex justify-content-end mt-3">
            <MaterialButton color="#1d1d1d" hoverColor="#333333" textColor="white" variant="contained" onClick={toggle}>
              Cancel
            </MaterialButton>
          </div>
        </div>
      </MaterialModal>
    </div>
  );
};
