import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classnames from "classnames";
import { WatchdogLink, WatchdogTextLink } from "./WatchdogLink";
import { Container, Jumbotron, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import MaterialButton from "../MaterialComponents/MaterialButton";
import MaterialModal from "../MaterialComponents/MaterialModal";
import "animate.css/animate.css";

export default props => {
  let bashCode = "curl --retry 3 -d 'log=Watchdogger, checking in!' " + WatchdogTextLink(props.switchId);
  let pythonCode = `import requests\nres = requests.get("${WatchdogTextLink(props.switchId)}")`;

  const copyLinkDefaultText = "Copy Link";
  const copyCodeDefaultText = "Copy Code";

  const [usageModal, setUsageModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [copyCodeText, setCopyCodeText] = useState(copyCodeDefaultText);
  const [copyCodeSuccess, setCopyCodeSuccess] = useState(false);
  const toggleUsageModal = () => setUsageModal(!usageModal);
  const switchTab = tab => {
    activeTab !== tab && setActiveTab(tab);
  };
  const [codeCopyText, setCodeCopyText] = useState(bashCode);
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false);
  const [copyLinkText, setCopyLinkText] = useState(copyLinkDefaultText);

  useEffect(() => {
    if (copyLinkSuccess) {
      setCopyLinkText(" Copied! ");
      setCopyLinkSuccess(false);
      setTimeout(() => {
        setCopyLinkText(copyLinkDefaultText);
      }, 2000);
    }
  }, [copyLinkSuccess]);

  useEffect(() => {
    if (copyCodeSuccess) {
      setCopyCodeText(" Copied! ");
      setCopyCodeSuccess(false);
      setTimeout(() => {
        setCopyCodeText(copyCodeDefaultText);
      }, 2000);
    }
  }, [copyLinkSuccess, copyCodeSuccess]);

  return (
    <div className="animated fadeIn center" style={{ animationDuration: "1.3s" }}>
      <Jumbotron className="usage-jumbotron">
        <WatchdogLink switchId={props.switchId} />
        <Container style={{ marginTop: "15px" }}>
          <div className="d-none d-md-inline">
            <MaterialButton
              textColor="white"
              size="large"
              color="#0098d4"
              hoverColor="#006c96"
              variant="contained"
              width="100"
              onClick={toggleUsageModal}
            >
              Usage
            </MaterialButton>
          </div>
          <div className="d-inline d-md-none">
            <MaterialButton
              textColor="white"
              size="small"
              color="#0098d4"
              hoverColor="#006c96"
              variant="contained"
              width="100"
              onClick={toggleUsageModal}
            >
              Usage
            </MaterialButton>
          </div>
          {"  "}
          <div className="d-inline d-md-none">
            <CopyToClipboard text={WatchdogTextLink(props.switchId)} onCopy={() => setCopyLinkSuccess(true)}>
              <MaterialButton textColor="white" size="small" color="#0098d4" hoverColor="#006c96" variant="contained" width="100">
                {copyLinkText}
              </MaterialButton>
            </CopyToClipboard>
          </div>
          <div className="d-none d-md-inline">
            <CopyToClipboard text={WatchdogTextLink(props.switchId)} onCopy={() => setCopyLinkSuccess(true)}>
              <MaterialButton textColor="white" size="large" color="#0098d4" hoverColor="#006c96" variant="contained" width="100">
                {copyLinkText}
              </MaterialButton>
            </CopyToClipboard>
          </div>
          <MaterialModal isOpen={usageModal} setIsOpen={setUsageModal} title="Watchdog Usage" style={{ width: "60vw", minWidth: "300px" }}>
            <ModalBody>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        switchTab("1");
                        setCodeCopyText(bashCode);
                      }}
                    >
                      Bash
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        switchTab("2");
                        setCodeCopyText(pythonCode);
                      }}
                    >
                      Python
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {/* <Row>
                    <Col sm="12"> */}
                    <SyntaxHighlighter language="bash" style={monokai} wrapLines={true}>
                      {bashCode}
                    </SyntaxHighlighter>
                    {/* </Col>
                  </Row> */}
                  </TabPane>
                  <TabPane tabId="2">
                    {/* <Row>
                    <Col sm="12"> */}
                    <SyntaxHighlighter language="python" style={monokai} wrapLines={true}>
                      {pythonCode}
                    </SyntaxHighlighter>
                    {/* </Col>
                  </Row> */}
                  </TabPane>
                </TabContent>
              </div>
            </ModalBody>
            <div className="d-flex justify-content-end">
              <CopyToClipboard text={codeCopyText} onCopy={() => setCopyCodeSuccess(true)} className="mr-2">
                <MaterialButton color="#0098d4" hoverColor="#006c96" textColor="white" variant="contained">
                  {copyCodeText}
                </MaterialButton>
              </CopyToClipboard>
              <div style={{ width: "6px" }}></div>
              <MaterialButton color="#1d1d1d" hoverColor="#333333" textColor="white" variant="contained" onClick={toggleUsageModal}>
                Cancel
              </MaterialButton>
            </div>
          </MaterialModal>
        </Container>
      </Jumbotron>
    </div>
  );
};
